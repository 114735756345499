.login-container {
  height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.login-header {
  font-size: 34px;
  font-weight: bold;
  font-weight: 400;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
}

.logo-container .nandi-logo {
  width: 48px !important;
  height: 48px !important;
}

.create-account {
  margin-top: 20px;
  text-align: left;
}

.or {
  text-align: center;
  margin-top: 10px !important;
}

.googleLogo {
  margin-right: 15px;
}
