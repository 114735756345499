.header-container {
  max-height: 48px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px !important;
  background-color: white !important;
  border-bottom: 1px solid #dedede;
  box-shadow: none;

  .header-icons {
    padding: 12px;
  }
}
