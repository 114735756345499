.task-card-container {
  width: "100%";
  min-width: 380px;
  max-width: 380px;
  padding: 0 16px;
  background-color: "white" !important;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
  }
  .card-content {
    padding: 0px !important;
    width: 100%;
  }
  .heading-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title-box {
    display: flex;
    align-items: space-between;
  }
  .task-content {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: space-between;
    margin-top: 6px;
  }
  .task-card-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .task-details {
    font-size: 12px;
    padding: 0px 6px 0px 6px;
  }
  .task-values {
    font-size: 12px;
    font-weight: 400;
  }
  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .avatar {
    width: 16px;
    height: 16px;
  }
}
