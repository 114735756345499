.dashboard-container {
  display: flex;
  flex-direction: row;
  .root-container {
    flex-direction: row;
    display: flex;
    margin: 80px 16px 16px 16px;
    .outlet-container {
      max-width: 400px;
      height: calc(100vh - 96px);
      margin-right: 16px;
    }
    .indoor-map-container {
      flex: 1;
      padding: 8px;
      height: 100%;
      width: calc(100vw - 522px);
    }
  }
}
