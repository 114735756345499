.table-header {
    background-color: #f2f2f2;
  }
  
  .table-header-cell {
    color: #333;
    font-weight: bold;
  }
  
  .table-row {
    border-bottom: 1px solid #ddd;
  }
  
  .details-row {
    background-color: #f9f9f9;
  }
  
  .details-row .table-header {
  background-color: #f2f2f2;
}
