.templates_container {
  min-width: 400px;
  .templates_header {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .templates_list {
    overflow-y: auto;
    height: calc(100vh - 168px);
    overflow-x: hidden;
    background-color: transparent;
  }
}
