.add_robot_container {
  min-width: 400px;
  .add_robot_toolbar {
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .add_robot_container {
    overflow-y: auto;
    height: calc(100vh - 144px);
    overflow-x: hidden;
    padding: 16px 8px 8px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .add_robot_footer {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}
