.performance-page {
    display: flex;
    height: 100vh;
    margin-left: 68px;
  
    .content {
      flex: 1;
      padding: 20px;
      overflow-y: auto;
    }
  
    .charts {
      margin-top: 4px;
    }
  }
  