.add_task_container {
  min-width: 400px;
  .add_task_toolbar {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px !important;
    padding-right: 8px !important;
    .add_task_box {
      display: flex;
      align-items: center;
      .add_task_back_button {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
  .create_task_container {
    overflow-y: auto;
    height: calc(100vh - 172px);
    overflow-x: hidden;
    padding: 16px;
  }
}
