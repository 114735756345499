.buildings-container {
  margin: 64px 16px 16px 16px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  .buildings-header {
    padding: 16px 16px 16px 0!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .buildings-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
