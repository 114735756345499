.user-container {
  margin: 80px;
  width: calc(100% - 80px);
  .header-container {
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .table-container {
    margin: 16px;
    overflow: hidden;
    .table-header {
      background-color: #f5f5f5;
    }

    .table-header-cell {
      font-weight: bold;
      text-transform: uppercase;
    }

    .table-row {
      &:hover {
        background-color: #f1f1f1;
        cursor: pointer;
      }
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .page-title-container {
      flex-grow: 1;
      text-align: center;
    }
  }
}
