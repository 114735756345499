.drawer-container {
  .list-container {
    .list-item-container {
      .list-item-button {
        min-height: 48;
      }
      .list-item-icon {
        min-width: 0;
        justify-content: center;
      }
    }
  }
}
