$primary-color: #fe57db;
$padding: 10px 40px !important;

.contained-button {
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: $primary-color;
  color: white !important;
  border: none;
  &:hover {
    background-color: darken($primary-color, 10%);
  }
  padding: $padding;
  text-transform: none !important;
}

.outlined-button {
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: white;
  color: $primary-color;
  border: 2px solid $primary-color;
  &:hover {
    background-color: lighten($primary-color, 60%);
  }
  padding: $padding;
  text-transform: none !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-column {
  .flex-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.common-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vertical-center {
  min-height: "100vh";
  min-width: "100vw";
  display: "flex";
  align-items: "center";
  justify-content: "center";
}
