.building-card {
  margin: 0px 16px 0 0;
  width: 300px;
  cursor: pointer;
  background-color: white !important;

  &:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }

  .buildings-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .building-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  .building-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
