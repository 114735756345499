.tasks_container {
  min-width: 400px;
  .tasks_header {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .tasks-list {
    overflow-y: auto !important;
    height: calc(100vh - 168px) !important;
    overflow-x: hidden;
    background-color: transparent;
  }
}