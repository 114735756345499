body {
  margin: 0;
  font-family: "Sofia Sans Variable";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: "Sofia Sans Variable";
}

.common-styles-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.common-styles-rows {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.common-styles {
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-button-contained {
  background-color: #fe57db !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  padding: 10px 40px !important;
  width: 49% !important;
  text-transform: none !important;
}
.secondary-button-outlined {
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: #fe57db;
  border: 1px solid #fe57db;
  padding: 10px 40px !important;
  width: 49% !important;
  text-transform: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 8px;
}

.vertical_center {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}