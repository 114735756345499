.indoor_map_container {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  position: relative;
  #canvas {
    position: "relative";
  }
}
