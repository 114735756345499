.map_container {
  .button_group_container {
    margin: 8px;
    width: "fit-content";
  }
  .map_interaction_container {    
    margin: 8px;
    padding: 0 8px;
    width: "fit-content";
    background-color: white;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
  }
}
